import Vue from 'vue';
import './services/handleErrors.js'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import router from './routes/router';
import store from './store/index'
import VueNotifications from 'vue-notifications'
import VueNotificationsOptions from './util/notification.js'
import VueTheMask from 'vue-the-mask'
import vTitle from 'vuejs-title'
import BaseButton  from './components/BaseButton.vue'
import BaseTooltip  from './components/BaseTooltip.vue'
import { BToast } from 'bootstrap-vue'
import VueLazyload from 'vue-lazyload'
import VueTour from 'vue-tour'

// plugin setup
require('vue-tour/dist/vue-tour.css')

Vue.component('BaseTooltip', BaseTooltip)
Vue.component('BaseButton', BaseButton)
Vue.use(VueLazyload)
Vue.use(VueTour)
Vue.use(DashboardPlugin);
Vue.use(VueNotifications, VueNotificationsOptions)
Vue.use(VueTheMask)
Vue.use(vTitle, {
  cssClass: "my-title-bubble",
  fontSize: "12px"
})
Vue.component('b-toast', BToast)

Vue.prototype.$store = store

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
});
